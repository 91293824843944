<template>
  <div v-loading="loading" class="content">
    <!-- 上边 -->
    <el-row style="margin-bottom:10px">
      <el-col :span="12" style="display: flex;">
        <search @success="init()" @cancel="cancel">
          <el-form>
            <el-form-item label="库存金额为0 ：" :label-width="formLabelWidth">          
              <el-radio-group v-model="listQuery.hideEndAmount0">
                <el-radio :label="true">隐藏</el-radio>
                <el-radio :label="false">显示</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </search>
     
        <el-button style="margin-left:10px" size="small" type="primary" plain @click="init(1)">刷新</el-button>

        <el-button :disabled="!$buttonStatus('ch_gzhsff_yjll')" size="small" type="success" plain @click="oneKeyLl()">一键领料</el-button>
        <el-tooltip placement="top">
          <template #content>
            <div style="max-width: 500px;">取整单位：个、台、辆、张、支、瓶、包、箱、卷、桶、袋、打、双、对、 
                套、块、枚、罐、盆、束、羽、条、头、尾、本、份、板、片、粒、管、
                根、把、只、件、架、箱、付、副</div>
          </template>
          <el-button :disabled="!$buttonStatus('ch_gzhsff_ablck')" size="small" type="success" plain @click="ablck()" icon="Top">按比例出库</el-button>
        </el-tooltip>
      </el-col>
      <el-col :span="12">
        <div style="float:right">
          <el-button :disabled="!$buttonStatus('ch_gzhsff_gchs')" size="small" v-if="info.statusDetail && info.statusDetail.projectCount == 0" type="success" @click="doProduceClick()">工程核算</el-button>
          <el-button :disabled="!$buttonStatus('ch_gzhsff_zc')" size="small" type="success" @click="doProduce(1)">暂存</el-button>
          <el-button :disabled="!$buttonStatus('ch_gzhsff_czgchs')" size="small" v-if="info.statusDetail && info.statusDetail.projectCount == 1" type="warning" @click="reset()">重置工程核算</el-button>
          <costSetting :disabled="!$buttonStatus('ch_gzhsff_jzkmsz')" type="project" @success="getRetent()"></costSetting>
          <el-button size="small" type="primary" @click="daoruRight1()" icon="FolderAdd" :loading="daoruLoading" plain> 导入</el-button>
          <el-button size="small" type="primary" @click="daochuRight()" icon="FolderOpened" :loading="daochuLoading" plain> 导出</el-button>
        </div>
      </el-col>
      <el-col style="color: red;">
        <span style="font-size: 15px;">
          注意：
        </span>
        <span>
          出库为0的出库金额为0，存货数量等于出库数量时,全部出库的出库金额为存货金额，这两种情况不取excel中填写的出库金额的数值
        </span>
      </el-col>
    </el-row>

    <!-- 左边 -->
    <div class="left-content">
      <div class="left-content-item">
        <el-row>
          <div class="left-total">
            <div style="float:right"><span>出库总金额：{{total.ckzje}} 成本比例{{total.cbbl}}%</span></div>
            <div style="display:inline-block;">
              <el-input style="width:120px" size="small" v-model="this.leftListQuery.inventoryName" placeholder="筛选名称"></el-input>
              <el-button @click="changeLeftInventory('find')" size="small" type="primary" plain>筛选</el-button>
            </div>
          </div>
          <div v-if="info.leftFee" class="left-content-item"  style="width:100%">
            <div class="left-title">
                其他费用
            </div>
            <el-row v-for="(item,index) in oldInfo.leftFee" :key="index">
              <el-col :span="16">
                <div class="left-box">
                  {{item.name}}
                </div>
              </el-col>
              <el-col :span="8">
                <div class="left-box">
                  <input style="height:24px" @change="leftTotal()" v-model.number="item.amount" placeholder="请输入金额" />
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="left-title">
            <input type="checkbox" :checked="isAllSelected" style="position: absolute;top: 5px;left: 7px;" @click="allSelect"/>
              原材料
          </div>
          <el-col :span="3">
            <div class="left-box">
              存货数量
            </div>
          </el-col>
          <el-col :span="3">
            <div class="left-box">
              存货单价
            </div>
          </el-col>
          <el-col :span="4">
            <div class="left-box">
              存货金额
            </div>
          </el-col>
          
          <el-col :span="3">
            <div class="left-box">
              出库数量
            </div>
          </el-col>
          <el-col :span="3">
            <div class="left-box">
              出库单价
            </div>
          </el-col>
          <el-col :span="4">
            <div class="left-box">
              出库金额
            </div>
          </el-col>
          <el-col :span="4">
            <div class="left-box">
              结算后数量
            </div>
          </el-col>
        </el-row>
        <el-scrollbar :style="{height:contentStyleObj1}"> 
          <div >
            <!-- 明细 -->
            <el-row v-for="(item,index) in info.leftInventory" :key="index">
              <!-- title -->
              <el-col :span="24">
                <div class="leftOne subject">
                  <ul style="display:inline-flex">
                    <li style="padding-right: 8px;" @click="singleSelect(item,index)">
                      <input type="checkbox" :checked="item.checked" :style="{marginLeft:0,marginTop:'9px'}"/>
                    </li>
                    <el-tooltip effect="dark" :content="item.name" placement="top" :disabled="!item.name">
                      <li style="width:200px" class="ccyc">
                        <span >{{item.name}}</span>
                      </li>
                    </el-tooltip>
                    <el-tooltip effect="dark" :content="item.subjectName" placement="top" :disabled="!item.subjectName">
                      <li style="width:180px" class="ccyc">
                        <span >科目:{{item.subjectName}}</span>
                      </li>
                    </el-tooltip>
                    <li>类型: {{$inventoryTypeFilter(item.inventoryType)}}</li>
                    <li>单位: {{item.unit}}</li>
                  </ul>
                </div>
              </el-col>
              <el-col :span="24">
                <el-row >
                  <el-col :span="3">
                    <div class="left-box">
                      {{item.count}}
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="left-box">
                      <span v-if="item.count">{{(item.amount/item.count).toFixed(2)}}</span>
                      <span v-else>0</span>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="left-box">
                      {{item.amount}}
                    </div>
                  </el-col>
                  
                  <el-col :span="3">
                    <div class="left-box">
                      <qzf-input style="width: 90%;" @changeLx="changeLeft(item, 'count', item)" v-model:num="item.useCount" placeholder="请输入出库数量" />
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="left-box">
                      <qzf-input style="width: 90%;" @changeLx="changeLeft(item, 'price', item)" v-model:num="item.usePrice" placeholder="请输入出库单价" />
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="left-box">
                      <qzf-input style="width: 90%;" @changeLx="changeLeft(item, 'amount', item)" v-model:num="item.useAmount" placeholder="请输入出库金额" />
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="left-box">
                      {{((item.count*1000000-item.useCount*1000000)/1000000).toFixed(6) * 1}}
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </el-scrollbar>
        <el-row style="background-color: #f5f7fa;">
          <el-col :span="3">
            <div class="left-box">
              {{ sumAmount }}
            </div>
          </el-col>
          <el-col :span="3">
            <div class="left-box">
              -
            </div>
          </el-col>
          <el-col :span="4">
            <div class="left-box">
              {{ sumAmount2 }}
            </div>
          </el-col>
          <el-col :span="3">
            <div class="left-box">
              {{ sumAmount3 }}
            </div>
          </el-col>
          <el-col :span="3">
            <div class="left-box">
              -
            </div>
          </el-col>
          <el-col :span="4">
            <div class="left-box">
              {{ sumAmount4 }}
            </div>
          </el-col>
          <el-col :span="4">
            <div class="left-box">
              {{ (sumAmount*1000000 - sumAmount3*1000000)/1000000 }}
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="pagination">
        <qzf-pagination v-show="leftPageTotal>0" :total="leftPageTotal" :pageSizes="[10, 20, 50, 100, 200 ,300,500,1000,3000]" v-model:page="this.leftListQuery.page" v-model:limit="this.leftListQuery.limit" @pagination="changeLeftInventory" />
      </div>
    </div>

     <!-- 右边 -->
     <div class="right-content" style="float:right">
      <!-- 存货原材料 -->
      <div class="left-content-item">
        <el-row>
          <!-- title -->
          <div class="left-total">
            <div style="float: right;"><span> 生产总金额：{{total.sczje}}</span></div>
          </div>
          <div class="left-title">
              工程
          </div>
          <el-col :span="16">
            <div class="left-box">
              服务
            </div>
          </el-col>
          <el-col :span="8">
            <div class="left-box">
              金额
            </div>
          </el-col>
        </el-row>
 
        <div :style="{height:contentStyleObj}" class="heightDetail" >
          <!-- 明细 -->
          <el-row v-for="(item,index) in info.rightFw" :key="index">
            <el-col :span="24">
              <el-row >
                <el-col :span="16">
                  <div class="left-box">
                    {{item.name}}
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="left-box">
                    {{item.amount}}
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="pagination">
        <qzf-pagination v-show="rightPageTotal>0" :total="rightPageTotal" :pageSizes="[10, 20, 50, 100, 200 ,300,500,1000,3000]" v-model:page="this.rightListQuery.page" v-model:limit="this.rightListQuery.limit" @pagination="changeRightInventory" />
      </div>
    </div>

    <!-- 弹框领料出库确认 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="工程核算确认" v-model="dialogVisible" width="45%" >
      <el-form label-position="right" size="small">
        <el-form-item>
          <span class="slot_label">成本总金额：</span>
          <span>{{this.total.ckzje}}</span>
        </el-form-item>
        <el-form-item>
          <span class="slot_label">是否需要生产成本留底：</span>
          <el-radio-group v-model="radio">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="radio == 1 && this.oldInfo.retentionAmount">
          <el-form-item v-for="(item,index) in this.oldInfo.retentionAmount" :key="index">
            <el-tooltip :content="item.name" placement="top-start" effect="dark">
              <div class="slot_label">{{ item.name }}：</div>
            </el-tooltip>
            <qzf-input  v-model:num="item.amount" placeholder="请输入留底金额" style="width:200px"/>
          </el-form-item>
        </div>
      
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small">取 消</el-button>
          <el-button type="primary" @click="doProduce(0)" size="small">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      v-model="daoruFormVisible"
      title="导入"
      height="50vh"
    >
      <el-form size="small" label-position="right" label-width="5px">
        <el-form-item label="">
          <el-input
            style="width: 60%; margin-right: 10px"
            v-model="path"
          ></el-input>
          <input
            size="small"
            ref="excel-upload-input"
            class="excel-upload-input"
            style="display: none"
            type="file"
            accept=".xlsx, .xls, .xml"
            @change="handleClick"
          />
          <el-button type="primary" @click="handleUpload()" size="small"
            >请选择文件</el-button
          >
        </el-form-item>
      </el-form>
      <div>
        <div style="text-align: right">
          <el-button
            size="small"
            type="primary"
            @click="uploadExcel"
            v-loading="daoruLoading"
            >上传</el-button
          >
        </div>
      </div>
    </el-dialog>

    <!-- 暂估入库 -->
    <inventoryStock @success="zgrkSuccess" ref="zgrk" name="暂估入库" from="convert" @convert="dialogVisible = true"></inventoryStock>
  </div>
</template>

<script>
import { projectCheckInfo, projectCheck, reverseProject } from"@/api/inventory.js"
import inventoryStock from "./components/inventoryStock.vue"
import costSetting from "./components/costSetting.vue"
import {projectCheckSheet} from '@/api/export'
import { projectChecks } from '@/api/import'
export default {
  name:'projectConvert',
  components: {
    inventoryStock,
    costSetting,
  },
  computed: {
    // 是否全选
    isAllSelected() {
      if(this.info.leftInventory.length == 0){
        return false
      }
      return this.info.leftInventory.every(el => {
        return el.checked;
      });
    },
    //left金额合计 数量 金额 出库数量 出库金额 
    sumAmount() {
      let count = 0;
        this.info.leftInventory.map(v => {
          if (v.count == undefined){
            v.count = 0
          }
          count += v.count*1
        })
      return Number(count.toFixed(6));
    },
    sumAmount2() {
      let count2 = 0;
        this.info.leftInventory.map(v => {
          if (v.amount == undefined){
            v.amount = 0
          }
          count2 += v.amount*1
        })
      return Number(count2.toFixed(6));
    },
    sumAmount3() {
      let count3 = 0;
        this.info.leftInventory.map(v => {
          if (v.useCount == undefined){
            v.useCount = 0
          }
          count3 += v.useCount*1
        })
      return Number(count3.toFixed(6));
    },
    sumAmount4() {
      let count4 = 0;
        this.info.leftInventory.map(v => {
          if (v.useAmount == undefined){
            v.useAmount = 0
          }
          count4 += v.useAmount*1
        })
      return Number(count4.toFixed(6));
    },

    // sumAmount5() {
    //   let count5 = 0;
    //     this.oldInfo.rightFw.map(v => {
    //       if (v.amount == undefined){
    //         v.amount = 0
    //       }
    //       count5 += v.amount*1
    //     })
    //   return Number(count5.toFixed(6));
    // },
    
  },
  data() {
    return {
      daoruFormVisible:false,
      daoruLoading:false,
      rawFile: "",
      path: "",
      selects: [],
      daochuLoading:false,
      radio: 0,//领料加工是否显示留底金额
      dialogVisible: false,
      loading: false,
      formLabelWidth:'120px',
      drawer: false,
      listQuery: {
        refresh: 0,//1 强制刷新
        hideEndAmount0: true,//true 隐藏金额为0的
      },
      info: {
        leftInventory:[]
      },
      oldInfo:{},
      total: {
        ckzje: 0,//出库总金额
        cbbl: 0,//成本比例
        sczje:0,//生产总金额
      },
      leftListQuery: {
        limit: 20,
        page:1,
        inventoryName: "",
      },
      leftPageTotal:10,
      rightListQuery: {
        limit: 20,
        page:1,
        inventoryName: "",
      },
      rightPageTotal:10,
      contentStyleObj1:{},
      leftFeeHeight:0,
      beforeFreshInfo:{}
    }
  },
  created() {
    this.init()
    this.contentStyleObj= this.$getHeight(309)
    this.contentStyleObj1 = this.$getHeight(360+this.leftFeeHeight)

  },
  methods:{
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile;
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    uploadExcel() {
      this.daoruLoading = true;
      const param = new FormData(); // 创建form对象
      param.append("file", this.rawFile, this.rawFile.name);
      projectChecks(param).then((res) => {
        if (res.data.msg == "success") {
          this.daoruLoading = false;
          this.daoruFormVisible = false;
          let infoTemp = res.data.data.data
          if(infoTemp.leftInventory){
            infoTemp.leftInventory.forEach(item => {
              this.info.leftInventory.forEach(val => {
                if(item.id == val.id){
                  val.useCount = item.useCount
                  if(val.count == item.useCount){//当存货数量=出库数量 出库金额=存货金额
                    val.useAmount = val.amount
                  }else{
                    val.useAmount = item.useAmount
                  }
                  if(!item.useCount){//如果出库数量为零 出库金额一样为零
                    val.useAmount = 0
                  }
                }
              })
            });
          }
          // this.info = res.data.data.data
          if(this.info.leftInventory){
            this.info.leftInventory.map(v=>{
              if(v.count){
                v.usePrice = (v.amount/v.count).toFixed(2) * 1
              } else {
                v.usePrice = 0
              }
            })
          }
          if(this.info.rightInventorys){
            this.info.rightInventorys.map(v=>{
              if(v.saleCount){
                v.usePrice = (v.saleAmount/v.saleCount).toFixed(2) * 1
              }else{
                v.usePrice = (v.saleAmount).toFixed(2) * 1
              }
            })
          }
          this.oldInfo = this.$qzfCopy(this.info)
          this.changeLeftInventory()
          this.changeRightInventory()
          this.initPrice()
        }
      });
    },
     //导入更新右侧数据
     daoruRight1(){
      this.rawFile = ""
      this.path = ""
      this.daoruFormVisible = true;
    },
    //导出导入更新右侧数据
    daochuRight(){
      // let param = {
      //   query:this.listQuery,
      //   types:1,
      //   flag:"right"
      // }
      this.daochuLoading = true
      projectCheckSheet(this.listQuery).then(res =>{
        this.daochuLoading = false
        window.open(res.data.data.url)
      })
    },
     //分页筛选
    changeLeftInventory(a){
      let left = this.oldInfo.leftInventory
      let coms = []
      if(a == 'find'){
        this.leftListQuery.page = 1
        this.leftListQuery.limit = 20
        coms = left.filter(v=>{
          return v.name.indexOf(this.leftListQuery.inventoryName) > -1
        })
      } else {
        if(this.leftListQuery.inventoryName){
          coms = left.filter(v=>{
            return v.name.indexOf(this.leftListQuery.inventoryName) > -1
          })
        }else{
          coms = left
        }
      }

      this.leftPageTotal = coms ? coms.length : 0 //加个判断
      this.info.leftInventory = coms ? coms.slice((this.leftListQuery.page - 1) * this.leftListQuery.limit, this.leftListQuery.page * this.leftListQuery.limit) : []
    },
    changeRightInventory(a){
      let right = this.oldInfo.rightFw ? this.oldInfo.rightFw : []
      let coms = []
      if(a == 'find'){
        this.rightListQuery.page = 1
        this.rightListQuery.limit = 20
        coms = right.filter(v=>{
          return v.name.indexOf(this.rightListQuery.inventoryName) > -1
        })
      } else {
        if(this.rightListQuery.inventoryName){
          coms = right.filter(v=>{
            return v.name.indexOf(this.rightListQuery.inventoryName) > -1
          })
        }else{
          coms = right
        }
      }
      if(coms){
        this.rightPageTotal = coms.length
        this.info.rightInventorys = coms ? coms.slice((this.rightListQuery.page - 1) * this.rightListQuery.limit, this.rightListQuery.page * this.rightListQuery.limit) : []
      }else{
        this.rightPageTotal = 0
      }
    },

    changeLeft(item, type){
      if(type == 'count'){
        if(item.useCount == item.count && item.usePrice == (item.amount/item.count).toFixed(2)){
          item.useAmount = item.amount
        }else if(item.usePrice){
          item.useAmount = (item.useCount * item.usePrice).toFixed(2) * 1
        }else{
          if(item.count){
            item.usePrice = (item.amount/item.count).toFixed(2) * 1
            item.useAmount = (item.useCount * item.usePrice).toFixed(2) * 1
          }
        }
      }else if(type == 'price'){
        item.useAmount = (item.useCount * item.usePrice).toFixed(2) * 1
      }else{
        if(item.useCount){
          item.usePrice = (item.useAmount/item.useCount).toFixed(2) * 1
        }
      }
      this.leftTotal()
    },
    leftTotal(){
      //费用
      let feeAmount = 0
      if(this.oldInfo.leftFee){
        this.oldInfo.leftFee.map(v=>{
          feeAmount+=v.amount
        })
        this.leftFeeHeight = (this.oldInfo.leftFee.length +1)*30
        this.contentStyleObj1 = this.$getHeight(360+this.leftFeeHeight)
      }
      feeAmount = feeAmount.toFixed(2)*1
      //存货
      let inventoryAmount = 0
      let actualArr = this.selects.length > 0 ? this.selects : this.oldInfo.leftInventory
      if(actualArr){
        actualArr.map(v=>{
          if(v.useAmount){
            inventoryAmount+=v.useAmount
          }
        })
      }
      inventoryAmount = inventoryAmount.toFixed(2)*1


      //生产总金额
      let inventoryAmountSc = 0
      if(this.oldInfo.rightFw){
        this.oldInfo.rightFw.map(v=>{
          if(v.amount){
            inventoryAmountSc+=v.amount
          }
        })
      }
      this.total.sczje = inventoryAmountSc.toFixed(2) * 1
      this.total.ckzje = (feeAmount + inventoryAmount).toFixed(2) * 1
      this.total.cbbl = this.total.sczje?((this.total.ckzje/this.total.sczje) * 100).toFixed(2):0
    },
    //重置
    cancel(){
      this.listQuery= {
        refresh: 0,//1 强制刷新
        hideEndAmount0: true,//true 隐藏金额为0的
      }
      this.init()
    },
    //初始化数据
    init(refresh){
      if(refresh){
        this.listQuery.refresh = 1
        this.beforeFreshInfo = this.$qzfCopy(this.info)
      }else{
        this.listQuery.refresh = 0
        this.beforeFreshInfo = {}
      }
      this.loading = true
      projectCheckInfo(this.listQuery).then(res=>{
        this.loading = false
        this.info = res.data.data.info
        if(this.info.leftInventory){
          this.info.leftInventory.map(v=>{
            if(v.count){
              v.usePrice = (v.amount/v.count).toFixed(2) * 1
            } else {
              v.usePrice = 0
            }
          })
        }
        this.oldInfo = this.$qzfCopy(this.info)
        if(refresh){
          this.updateLeft(this.beforeFreshInfo)
        }
        this.changeLeftInventory()
        this.changeRightInventory()
        this.initPrice()
      })
    },
    getRetent(){
      projectCheckInfo(this.listQuery).then(res=>{
        this.info.retentionAmount = res.data.data.info.retentionAmount
        this.oldInfo = this.$qzfCopy(this.info)
        this.changeLeftInventory()
        this.changeRightInventory()
        this.initPrice()
      })
    },
    initPrice(){
      //计算缓存的出库单价
      if(this.oldInfo.leftInventory){
        this.oldInfo.leftInventory.map(v=>{
          this.changeLeft(v, 'amount', this.oldInfo.leftInventory)
        })
      }
      this.leftTotal()
    },
    //执行领料加工
    doProduce(type){
      let produceParam = this.$qzfCopy(this.oldInfo)
      if(type == 0){
        produceParam.leftInventory = this.selects.length != 0 ? this.selects : this.oldInfo.leftInventory
      }
      let param = {
        info: produceParam,
        type,
      }
    
      this.dialogVisible = false
      this.loading = true
      projectCheck(param).then(res=>{
        this.loading = false
        if(res.data.msg == "success"){
          this.$qzfMessage("操作成功")
          this.$bus.emit('inventoryCheckUpdate')
          this.init()
        }
      })
    },
    //领料加工 确认
    doProduceClick(){
      //判断是否需要暂估入库
      let zgrkList = []
      let produceList = this.selects.length != 0 ? this.selects : this.oldInfo.leftInventory
      if(produceList){
        produceList.map(v=>{
          if((v.useCount - v.count) > 0){
            let count = parseFloat((v.useCount - v.count).toFixed(6) * 1)
            let danjia = (v.useAmount/v.useCount).toFixed(2) * 1
            if(v.useCount == 0){
              danjia = (v.amount/v.count).toFixed(2) * 1
            }
            let amount = (count*danjia).toFixed(2) * 1
            zgrkList.push({
              count: count, // 数量
              amount:  amount,// 金额
              danjia: danjia,
              inventoryId:v.id,
            })
          }
        })
      }
      if(zgrkList.length != 0){
        this.$refs.zgrk.init(zgrkList)
        return
      }
      this.dialogVisible = true
    },
    //暂估入库回调
    zgrkSuccess(info){
      info.items.map(zgrk=>{
        this.oldInfo.leftInventory.map(v=>{
          if(v.id == zgrk.inventoryId){
            v.count = parseFloat((v.count + zgrk.count).toFixed(6) * 1)
            v.amount = (v.amount + zgrk.amount).toFixed(2) * 1
          }
        })
      })
    },
    //一键领料
    oneKeyLl(){
      this.oldInfo.leftInventory.map(v=>{
        v.useCount = v.count
        v.useAmount = v.amount
      })
      this.initPrice()
    },
    ablck(){
      let that = this
      this.$prompt('请输入成本比例（%）,当出库数量等于存货数量时，系统默认出库金额全部出库，成本比例可能存在误差', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        // 成功的操作 写在这里
        let amount = this.total.sczje * value / 100
        that.inProportion(amount)
      })
    },
    //按比例出库
    inProportion(amount){
      if(amount == 0){
        this.$qzfMessage("生产总金额不可为0", 1)
        return
      }
      let ckList = this.selects.length != 0 ? this.selects : this.oldInfo.leftInventory
      let amountSum = 0
      ckList.map(v=>{
        amountSum+=v.amount
      })
      if(amount > amountSum){
        this.$qzfMessage("成本大于当前存货总金额", 1)
        return
      }
      ckList.map(v=>{
        v.useAmount = amountSum?(v.amount/amountSum*amount).toFixed(2) * 1:0
        v.usePrice = v.count?(v.amount/v.count).toFixed(2) * 1:0
        v.useCount = v.usePrice?parseFloat((v.useAmount/v.usePrice).toFixed(6) * 1):0
        let danweis = ["个","台","辆","张","支","瓶","包","箱","卷","桶","袋","打","双","对","套","块","枚","罐","盆","束","羽","条","头","尾","本","份","板","片","粒","管","根","把","只","件","架","箱","付","副"]
        if(this.$checkStrInArray(danweis,v.unit)){
          if(Number.isInteger(v.count)){
            v.useCount = v.count <= 1 ? v.count : Math.ceil(v.useCount)
            v.usePrice = v.useCount?(v.useAmount/v.useCount).toFixed(2) * 1:0
          }else{
            v.useCount = v.count <= 1 ? v.count : (v.useCount).toFixed(2) * 1
            v.usePrice = v.useCount?(v.useAmount/v.useCount).toFixed(2) * 1:0
          }
        }
        v.useCount == v.count ? v.useAmount = v.amount:v.useAmount
      })
      this.initPrice()
    },
    //重置领料加工
    reset(){
      this.loading = true
      reverseProject({}).then(res=>{
        this.loading = false
        if(res.data.msg == "success"){
          this.info = res.data.data.info
          if(this.info.leftInventory){
            this.info.leftInventory.map(v=>{
              if(v.count){
                v.usePrice = (v.amount/v.count).toFixed(2) * 1
              } else {
                v.usePrice = 0
              }
            })
          }
          this.oldInfo = this.$qzfCopy(this.info)
          this.changeLeftInventory()
          this.changeRightInventory()
          this.initPrice()
          this.$qzfMessage("重置成功")
          this.$bus.emit('inventoryCheckUpdate')
        }
      })
    },
    updateLeft(item){
      for (let i = 0; i < this.oldInfo.leftInventory?.length; i++) {
        const element = this.oldInfo.leftInventory[i];
        item.leftInventory.map((v) => {
          if (v.id == element.id) {
            element.useAmount = v.useAmount;
            element.useCount = v.useCount;
            element.usePrice = v.usePrice;
          }
        });
      }
      // this.changeLeftInventory()
    },
    //单选
    singleSelect(row, index) {
      row.checked = !row.checked;
      this.info.leftInventory.splice(index, 1, row);
      this.screenChecked()
    },
    // 全选、全不选
    allSelect() {
      let checked = true;
      // 全选
      if (this.isAllSelected) {
        checked = false;
      }
      this.info.leftInventory = this.info.leftInventory.map(el => {
        el.checked = checked;
        return el;
      });
      this.screenChecked()
    },
    screenChecked(){
      this.selects = this.info.leftInventory.filter(v=>{return v.checked})
      this.leftTotal()
    },
  }
}
</script>

<style lang="scss" scoped>
 
.content{
  font-size: 13px;
  margin: 20px;
  // text-indent: 4px;
  .left-content{
    width: 49%;
    margin-right: 1%;
    height: 500px;
    display: inline-block;
  }
  .right-content{
    width: 50%;
    height: 500px;
    display: inline-block;
  }
  .left-content-item{
    width: 100%;
    border: 1px solid #efefef;
    .left-total{
      width: 100%;
      background: #fff;
      height: 30px;
      text-align: left;
      line-height: 30px;
      span{
        padding-right: 20px;
      }
    }
    .left-title{
      width: 100%;
      background: var(--themeColor,#17a2b8);
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #fff;
      position: relative;
    }
    .subject{
      background: #efefef;
    }
    .left-box{
      border-right: 1px solid #efefef;
      line-height: 30px;
      height: 30px;
      border-top: 1px solid #efefef;
      text-align: center;
      input {
        background-color: aliceblue;
      }
    }
    &:last-child {
      border-right: none;
    }
  }
}
.right-button{
  position: fixed;
  top: 250px;
  right: 0;
  height: 120px;
  width: 40px;
  font-size: 14px;
  margin: 0 auto;  
  line-height: 24px;
  background: var(--themeColor,#17a2b8);
  color: #fff;
  border-color: var(--themeColor,#17a2b8);
  border: 0;
  cursor:pointer;
}
// input{
//   width: calc(100% - 10px);
//   height: 100%;
//   display: inline-block;
//   outline: none;
// }
.leftOne {
  border-right: 1px solid #efefef;
  line-height: 30px;
  height: 30px;
  border-top: 1px solid #efefef;
  padding: 0 10px;
}
.leftOne span {
  padding-right: 25px;
}
.ccyc{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.slot_label{
  width:200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  color: #333;
  margin-right: 5px;
}
</style>